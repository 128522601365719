import React, {useState, useEffect} from 'react';
import JournalItem from './components/JournalItem';
import NewJournalItem from './components/NewJournalItem';
const Journal = () => {

    const [journalItems, setJournalItems] = useState([]);

    useEffect(() => {
        if(localStorage.getItem('SAVED_JOURNAL') && localStorage.getItem('SAVED_JOURNAL').trim() !== "") {
            setJournalItems(localStorage.getItem('SAVED_JOURNAL').split(','));
        }
    }, []);
    useEffect(() => {
        localStorage.setItem('SAVED_JOURNAL', journalItems);
    }, [journalItems])

    const createItem = (text) => {
        setJournalItems(journalItems.concat(text.trim()));
        //console.log(event.key + " is the key" + " and the text is " + event.target.value);
        //console.log("New item function, should handle onKeyUp");
        console.log("Adding new item " + text);
    }

    const deleteItem = (index) => {
        var newJournalItems = [...journalItems];
        newJournalItems.splice(index, 1);

        setJournalItems(newJournalItems);
    }

    const clear = () => {
        var emptyArr = [];
        setJournalItems(emptyArr);
    }

    return(
        <>
            <div className="container main">
                <h1>Gratefullness Journal</h1>
                <p>What are you grateful for today?</p>                     
                <div className="journal-items">
                {journalItems.length ? 
                    journalItems.map((entry, index) => 
                        <JournalItem 
                        key={index} 
                        text={entry} 
                        deleteItem={() => deleteItem(index)} />    
                    )
                    :
                    <p className="text-info"></p>
                }
                </div>
                <NewJournalItem createItem={createItem}/>
                <a className="btn btn-info" onClick={clear}>Clear All</a>
                
            </div> 
        </>
    );
}

export default Journal;