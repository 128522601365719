import React from 'react';

const JournalItem = ({text, deleteItem}) => {
    return(
        
        <div className="journal-item row row-no-gutters">
            <div className="col-xs-9 vcenter text-left">{text}</div>
            <div className="col-xs-2 vcenter actions text-right"><a className="action-delete btn btn-danger" onClick={deleteItem}>Delete</a></div>
        </div>
        
    );
}

export default JournalItem;