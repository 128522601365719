import React from 'react';

const onEnter = (cb) => {
    return function(event) {
        if(event.key === 'Enter') {
            const text = event.target.value.trim();
            if(text.length) {
                cb(text); 
                event.target.value = '';
            }
        }
    }
};

const NewJournalItem = ({createItem}) => {
    return(
        <div className="row row-no-gutters">
            <div class="col-12 col-md-5 col-sm-6">
                <input type="text" className="journal-new--input" placeholder="Type and press enter" onKeyUp={onEnter(createItem)}/>
            </div>
        </div>
    );
};

export default NewJournalItem;