import React from 'react';

const OtherPage = (props) => {
    return(
        <div className="container main">
            <h1>About</h1>
            <p>This is a small project of mine. It's a very simple todo-style gratefulness journal, there's no backend database as it's not designed to track or save your journal items. The point of this little app is solely based around the action of journaling. There's been research about saying that journaling like this can be of benefit mentally when done regularly.</p>
            <p>&nbsp;</p>
            <code>
            <p>Version: 1.0</p>
            <p>Updates coming: Automatically clear after 24 hours.</p>
            </code>
        </div>
    );
};

export default OtherPage;