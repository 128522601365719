import React from 'react';
import Timer from './Timer';
import OtherPage from './OtherPage';
import Journal from './Journal';
import logo from './logo.svg';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import './App.css';

function App() {
  return (
    <Router>
      <ul className='simple-nav'>
        <li>
        <img src="favicon-64x64.ico" width="25"/>
        </li>
        <li>
          <Link to="/">Home (Journal)</Link>
        </li>
        <li>
          <Link to="/OtherPage">About</Link>
        </li>
      </ul>
      <Switch>
        <Route path="/OtherPage">
          <OtherPage />
        </Route>
        <Route path="/">
          <Journal/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
